<style lang="less" scoped>
  .org-block {
    display: flex;justify-content: space-between;padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid #EEE;
  }
  .org-item {
    flex: 1;
    border: 1px solid #EEE;
    padding: 10px;
    border-radius: 5px;
    & + .org-item {
      margin-left: 30px;
    }
  }
  .org-title {
    margin-bottom: 10px;
  }
</style>

<template>
  <div class="evaluation-form">
    <fm-form>
      <fm-form-item label="考核年份">
        <fm-date-picker v-verifier required v-model="value.year" type="year" placeholder="请选择年份"></fm-date-picker>
      </fm-form-item>
    </fm-form>
    <div class="org-block">
      <div class="org-item">
        <div class="org-title">主管领导:</div>
        <worker-user-choose v-model="value.leadUserIds" />
      </div>
      <div class="org-item">
        <div class="org-title">考核单位:</div>
        <worker-user-choose v-model="value.orgUserIds" />
      </div>
    </div>
  </div>
</template>

<script>
import WorkerUserChoose from './choose/workerUserChoose'

function getDefault () {
  return {
    year: new Date(new Date().getFullYear() + 1),
    total: null,
    orgUserIds: [],
    leadUserIds: []
  }
}

function transData (data) {
  return JSON.parse(JSON.stringify(data, function (key, value) {
    if (['orgUserIds', 'leadUserIds'].includes(key)) {
      return value ? value.split(',').map(v => Number(v)) : []
    } else {
      return value
    }
  }))
}

export default {
  components: { WorkerUserChoose },
  props: {
    data: { type: Object, defualt: () => null }
  },
  data () {
    return {
      typeList: [{key: 'normal', label: '否'}, {key: 'append', label: '是'}],
      value: this.data ? transData(this.data) : getDefault()
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.value = this.data ? transData(this.data) : getDefault()
      }
    }
  },
  methods: {
    getData () {
      if (!this.value.orgUserIds.length) {
        throw new Error('请选择考核单位')
      }
      if (!this.value.leadUserIds.length) {
        throw new Error('请选择主管领导')
      }

      return JSON.parse(JSON.stringify(this.value, (key, value) => {
        if (key === 'year') {
          return value ? Number(this.$datetime.format(value, 'Y')) : null
        } else if (Array.isArray(value)) {
          return value.join(',')
        } else if (value instanceof Date) {
          return this.$datetime.format(value, 'Y-m-d H:i:s')
        } else {
          return value
        }
      }))
    }
  },
}
</script>
